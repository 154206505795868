<template>
	<div id="registerDiv">
		<h3 style="text-align: center;margin-bottom: 20px;">专家注册</h3>
		<van-form  @submit="submitForm">
			<van-field required v-model="form.name" label="姓名" placeholder="请输入用户名"
				:rules="[{ required: true, message: '' }]" />

			<van-field required readonly clickable :value="form.phone" @touchstart.native.stop="show = true" type="tel"
				label="手机号" placeholder="请输入手机号" :rules="[
					{ required: true, message: '' },
					{ pattern:/^1[3|4|5|7|8|9][0-9]\d{8}$/, message: '手机号格式不正确' }
				]" />
			<van-number-keyboard v-model="form.phone" :show="show" :maxlength="11" @blur="show = false" />

			<van-field required v-model="form.sendCode" center clearable label="短信验证码" placeholder="请输入短信验证码"
				:rules="[{ required: true, message: '' }]">
				<template #button>
					<van-button size="small" type="info" native-type="button" v-if="second>0">{{second}}秒后重发
					</van-button>
					<van-button size="small" type="info" native-type="button" @click="getCode" v-else>发送验证码</van-button>
				</template>
			</van-field>

			<van-field required v-model="form.password" :type="passWatch?'':'password'" label="设置密码" placeholder="请设置密码" :rules="[
					{ required: true, message: '' },
					{ pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/, message: '密码应为8~16位数字,字母组合' }
				]" :right-icon="passWatch?'http://www.zaototech.cn/api/public/image/eye.png':'http://www.zaoto.cn/api/public/image/eye2.png'" @click-right-icon="watchChange('password')"/>

			<van-field required v-model="form.password2" :type="passWatch2?'':'password'"  label="确认密码" placeholder="请确认密码"
				:rules="[{ required: true, message: '' }]" :right-icon="passWatch2?'http://www.zaoto.cn/api/public/image/eye.png':'http://www.zaototech.cn/api/public/image/eye2.png'" @click-right-icon="watchChange('password2')"/>

			<van-field name="checkbox" label=" ">
				<template #input>
					<van-checkbox v-model="agree" shape="square" icon-size="16px" />
					<span style="margin-left: 10px;">我同意</span>
					<span @click="dialogShow=true;" style="color: #0D81D6;">《注册协议》</span>
				</template>
			</van-field>

			<div style="margin: 16px;">
				<van-button round block type="info" native-type="submit">注册</van-button>
			</div>
		</van-form>

		<van-dialog v-model="dialogShow" title="注册协议">
			<div style="height: 300px;overflow-y:auto;padding:20px;">
				<div style="height: auto;" id="regist">
					<p class="title2">本人自愿申请成为招投研究院评标专家，承诺符合下列条件：</p>
					<p class="xieYi">1、从事相关专业领域工作满8年并具有高级专业技术职称。</p>
					<p class="xieYi">2、熟悉有关招标投标和政府采购的相关法律法规和业务知识。</p>
					<p class="xieYi">3、能够认真、公正、诚实、廉洁地履行职责。</p>
					<p class="xieYi">4、申请时，年龄未满70周岁且身体健康，能够承担评标工作。</p>
					<p class="xieYi">5、符合法律、法规和规章规定的其他条件。</p>
					<p class="title2">承诺提供以下申请材料，并保证材料真实有效:</p>
					<p class="xieYi">（一）证明本人身份的有效证件；</p>
					<p class="xieYi">（二）学历学位证书、专业技术职称证书或者具有同等专业水平的证明材料；</p>
					<p class="xieYi">（三）工作履历信息。</p>
					<p class="title1">申请须知</p>
					<p class="title2">一、征集方式</p>
					<p class="xieYi">招投研究院评标专家候选人采取个人申请和单位推荐两种方式，面向社会公开征集。</p>
					<p class="title2">二、申请程序：</p>
					<p class="xieYi">1、申请人须登录“招投研究院专家库页面”，注册并填报个人信息，专业信息及工作简历，资质信息。</p>
					<p class="xieYi">2、按照要求网上提交相关申请材料。</p>
					<p class="title2">三、专家管理</p>
					<p class="xieYi">
						评标专家入库以后，可根据系统授权，使用个人用户名和登录密码进入专家库管理信息系统，在线修改个人基本信息、专业信息及工作简历、资质信息并向招投研究院专家库提交修改申请、等待审核。</p>
					<p class="title2">四、权利和义务</p>
					<p class="xieYi">(一）担任评标委员会成员的评标专家享有下列权利：</p>
					<p class="xieYi">1、依法按照招标文件确定的评标标准和方法，对投标文件进行独立评审，提出评审意见，不受任何单位或个人的干预。</p>
					<p class="xieYi">2、接受参加评标活动的合法劳务报酬，劳务报酬的支付标准和办法由市政府有关招标投标行政监督部门分行业制定。</p>
					<p class="xieYi">3、向招标人或向有关行政监督部门反映评标活动中发现的违法违规行为。</p>
					<p class="xieYi">4、法律、法规规定的其他权利。</p>
					<p class="xieYi">(二）担任评标委员会成员的评标专家承担下列义务</p>
					<p class="xieYi">1、准时出席评标活动并客观公正地进行评标。</p>
					<p class="xieYi">2、遵守评标工作纪律，不得私下接触投标人，不得收受他人的财物或者其他好处，不得透露对投标文件的评审和比较、中标候选人的推荐情况以及与评标有关的其他情况。</p>
					<p class="xieYi">3、积极协助和配合有关行政监督部门的监督检查。</p>
					<p class="xieYi">4、评标专家工作单位发生变化，应及时修改个人相关信息。</p>
					<p class="xieYi">5、担任评标委员会成员的评标专家因故不能参加评标活动，应于评标前一个工作日内的工作时间向招投研究院专家库请假，以便及时调整补充评标专家。
						未按照上述要求请假，且无不可抗力原因，视为无故不参加评标活动。</p>
					<p class="xieYi">6、担任评标委员会成员的评标专家有下列情形之一，应当主动提出回避：</p>
					<p class="xieYi1">（1）投标人或者投标人的主要负责人的近亲属。</p>
					<p class="xieYi1">（2）与投标人存在人事或劳动关系。</p>
					<p class="xieYi1">（3）与投标人有其他社会关系或经济利益关系，可能影响公正评审。</p>
					<p class="xieYi1">（4）项目主管部门或者行政监督部门的行政工作人员。</p>
					<p class="xieYi">7、法律、法规规定的其他义务。</p>
					<p class="title2">五、暂停评标资格</p>
					<p class="xieYi">评标专家有下列情形之一，经招投研究院专家库确认后，予以暂停评标资格，并备案。</p>
					<p class="xieYi">（一）受到有关行政监督部门行政处罚。</p>
					<p class="xieYi">（二）在一个年度内，出席评标工作少于抽中次数三分之一。</p>
					<p class="xieYi">（三）在一个年度内，无不可抗力原因，无故三次及以上迟到评标活动。</p>
					<p class="xieYi">（四）在一个年度内，无故不参加评标活动两次。</p>
					<p class="xieYi">（五）因与招投标业务无关原因，与招标代理机构发生纠纷。</p>
					<p class="xieYi">（六）抽取评标，本人不参加让别人冒名顶替去评标。</p>
					<p class="xieYi">（七）其他违法违纪行为。</p>
					<p class="title2">六、专家停用</p>
					<p class="xieYi">评标专家有下列情形之一，经招投研究院专家库监督部门确认后，由招投研究院专家库停用。</p>
					<p class="xieYi">（一）年龄达到70周岁。</p>
					<p class="xieYi">（二）在一个年度内，无故不参加评标活动三次及以上。</p>
					<p class="xieYi">（三）使用不实信息和虚假材料骗取评标专家资格。</p>
					<p class="xieYi">（四）因在招标、评标以及其他与招标投标有关活动中从事违法行为而受到刑事处罚。</p>
					<p class="xieYi">（五）申请专家时，提供假资料。</p>
					<p class="xieYi">（六）其他违法违纪行为。</p>
				</div>
			</div>
		</van-dialog>

	</div>
</template>

<script>
	import Vue from 'vue';
	import {
		Form,
		Field,
		Button,
		Checkbox,
		NumberKeyboard,
		Dialog,
		Toast,
	} from 'vant';
	Vue.use(Form);
	Vue.use(Field);
	Vue.use(Button);
	Vue.use(Checkbox);
	Vue.use(NumberKeyboard);
	Vue.use(Dialog);
	Vue.use(Toast);
	export default {
		name: 'register',
		data() {
			return {
				form: {
					name: "",
					phone: "",
					sendCode: "",
					password: "",
					password2: "",
				},
				show: false,
				dialogShow: false,
				agree: false,
				second: 0,
				passWatch:false,
				passWatch2:false,
			}
		},
		methods: {
			submitForm() {
				var that=this;
				if (!this.agree) {
					this.$dialog.alert({title: '提示',message: '请勾选注册协议'});
					return false;
				}
				if (this.form.password != this.form.password2) {
					this.$dialog.alert({title: '提示',message: '两次密码不一致'});
					return false;
				}

				let param = new FormData();
				param.append("userName", this.form.name);
				param.append("num", this.form.phone);
				param.append("pwd", this.form.password);
				param.append("sendCode", this.form.sendCode);
				param.append("verify", sessionStorage.getItem("verify"));
				
				this.$axios.post(this.baseUrl3+'api/public/index.php/expert/sample/register', param)
					.then(res => {
						Dialog.alert({
							title: '提示',
							message: res.data.msg
						}).then(() => {});
						if (res.data.code == 200) {
							that.$router.push('/mobile/registerSuccess')
						}
					}).catch(function(error) {
						window.console.log(error)
					}); //ajax请求失败
			},
			getCode() {
				var that = this;
				if (this.form.phone == '') {
					Dialog.alert({
						title: '提示',
						message: '请先输入手机号'
					}).then(() => {});
					return false;
				}

				if (that.getCookie('sendCount')) {
					if (that.getCookie('sendCount') > 20) {
						
						Dialog.alert({
							title: '提示',
							message: '您今天发送的验证码已超过限定次数，请明天再试。'
						}).then(() => {});
						return false;
					}
				}
				let param = new FormData();
				param.append("mobile", this.form.phone);
				param.append("fun", 'expertRegister');
				this.$axios.post(this.baseUrl3+'api/public/index.php/expert/sample/sendCode', param)
					.then(res => {
						var count = 0;
						this.$dialog.alert({title: '提示',message: res.data.msg});
						if (res.data.code == 200) {
							sessionStorage.setItem("verify",res.data.data);
							this.second = 60;
							var interval = setInterval(function() {
								console.log(that.second);
								if (that.second > 0) {
									that.second--;
								}
							}, 1000);
							setTimeout(function() {
								clearInterval(interval)
							}, 70000);
							if (that.getCookie('sendCount')) {
								count = Number(that.getCookie('sendCount')) + 1;
							} //存在cookie,证明调用过发送短信
							else {
								count = 1;
							}
							var d = new Date();
							d.setTime(d.getTime() + (24 * 60 * 60 * 1000)); //一天
							var expires = "expires=" + d.toGMTString();
							document.cookie = "sendCount=" + count + "; " + expires;
						}
					}).catch(function(error) {
						window.console.log(error)
					}); //ajax请求失败

			},
			getCookie(cname) {
				var name = cname + "=";
				var ca = document.cookie.split(';');
				for (var i = 0; i < ca.length; i++) {
					var c = ca[i].trim();
					if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
				}
				return "";
			},
			watchChange(res){
				if(res=='password'){
					this.passWatch=!this.passWatch
				}else{
					this.passWatch2=!this.passWatch2
				}
			}
		},
		mounted() {

		}
	}
</script>

<style scoped>
	#regist p {
		margin: 5px 0;
		line-height: 25px;
	}

	.title1 {
		font-weight: 550;
		text-align: center;
		font-size: 14px;
	}

	.title2 {
		font-weight: 550;
		font-size: 14px;
	}

	.xieYi {
		padding-left: 14px;
		font-size: 14px;
	}

	.xieYi1 {
		padding-left: 28px;
		font-size: 14px;
	}
	#registerDiv /deep/ .van-icon img{
		height: 25px;
		width: 25px;
		vertical-align: middle;
		position: relative;
		left: -20px;
	}
</style>
